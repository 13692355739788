<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$storex.dispatch("logout").then(() => {
      this.$router.push("/").then(() => {
        window.location.reload();
      });
    });
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>
